function Header() {
  return (
    <header className='header-component'>
      <h1>Instakitties</h1>
      <p>All my kitties with their best foot forward!</p>
    </header>
  );
}

export default Header;
